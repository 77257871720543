const colors = {
  white: '#FFFFFF',
  neutralGrey80: '#333333',
  neutralGrey60: '#666666',
  neutralGrey30: '#b3b3b3',
  neutralGrey10: '#E6E6E6',
  neutralGrey5: '#F2F2F2',
  greenSucessGreen50: '#10923C',
  redErrorRed50: '#DA2D2A',
};

export type Colors = keyof typeof colors;

export default colors;
