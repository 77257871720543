const messages = {
  defaultErrorTitle: { key: 'default.error.title', defaultValue: 'Ops! Something went wrong!' },
  defaultErrorDescription: { key: 'default.error.description', defaultValue: 'Please try again later' },
  defaultInputFieldRequiredTitle: {
    key: 'default.error.input.required.title',
    defaultValue: 'You forgot to fill in all required fields',
  },
  defaultInputFieldRequiredDescription: {
    key: 'default.error.input.required.description',
    defaultValue: 'Fill in to continue',
  },
};

export default messages;
