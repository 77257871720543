import axios, { AxiosError } from 'axios';
import ApiError from 'types/ApiError';
import apiWsv from '../api/apiWsv';
import { KEY_API } from './constants';
import { RequestPortalMethods } from './types';

const ENDPOINT = 'ExpenseMobilityFinalV2//portal';

type ParamsType = {
  companyId: number;
  activeFL: boolean;
  userId?: number;
  hasSelectedCompany?: boolean;
  todos?: boolean;
};

type RequestBodyType = {
  idempresa: number;
  flativo: boolean;
  idusuariologado?: number;
  idempresaselecionada?: number;
  todos?: boolean;
};

const buildBodyRequest = (params: ParamsType) => {
  const { companyId, activeFL, userId, todos, hasSelectedCompany } = params;

  const requestBody: RequestBodyType = {
    idempresa: companyId,
    flativo: activeFL,
  };

  if (hasSelectedCompany) {
    requestBody.idempresaselecionada = companyId;
  }

  if (userId) {
    requestBody.idusuariologado = userId;
  }

  if (todos) {
    requestBody.todos = todos;
  }
  return requestBody;
};

const requestPortal = async <T>(method: RequestPortalMethods, params: ParamsType): Promise<Array<T> | ApiError> => {
  const requestBody: RequestBodyType = buildBodyRequest(params);

  const data = {
    key: KEY_API,
    method: method,
    param: requestBody,
  };
  try {
    const response = await apiWsv.post(ENDPOINT, data);
    return JSON.parse(response.data.response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      return {
        message: axiosError.message,
        status: Number(axiosError.status),
      } as ApiError;
    }
    return {
      message: `Unhandled error trying to ${method}:` + (error as Error).message,
      status: 500,
    } as ApiError;
  }
};

export default requestPortal;
