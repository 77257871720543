import { Alert, Snackbar } from '@mui/material';
import { styled } from '@mui/system';
import { BodyMini, BodyMiniBold } from 'components/UiComponents/DataDisplay/Typography';
import colors from 'styles/colors';

const getTypeAlertColor = (alertType?: string) =>
  alertType === 'SUCCESS' ? colors.greenSucessGreen50 : colors.redErrorRed50;

const SnackbarContainer = styled(Snackbar)`
  width: 95%;
  max-width: 320px;
  background-color: white;
  border: 1px solid ${(props) => getTypeAlertColor(props.key)};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;
const AlertContainer = styled(Alert)`
  width: 100%;
`;

const CustomAlertTitle = styled(BodyMiniBold)`
  color: ${colors.neutralGrey80};
`;

const AlertDescriptionText = styled(BodyMini)`
  color: ${(props) => getTypeAlertColor(props.id)};
`;

export { SnackbarContainer, AlertContainer, CustomAlertTitle, AlertDescriptionText };
