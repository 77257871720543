import { useTranslation } from 'react-i18next';
import { MessageType } from './types';

const useTranslate = () => {
  const { t: translationI18Next } = useTranslation();

  const translate = (message: MessageType) => {
    const messageTranslated = translationI18Next(message.key);
    if (!messageTranslated || messageTranslated === message.key) {
      return message.defaultValue;
    }

    return messageTranslated;
  };

  return { translate };
};

export default useTranslate;
