import { ThemeProvider } from '@mui/material/styles';
import CustomAlert from 'components/Alert';
import { AlertProvider } from 'contexts/AlertContext/AlertContext';
import AppRoutes from 'routes';
import theme from './styles/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AlertProvider>
        <CustomAlert />
        <AppRoutes />
      </AlertProvider>
    </ThemeProvider>
  );
}

export default App;
