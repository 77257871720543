import { useAlertContext } from '../../contexts/AlertContext/AlertContext';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { AlertContainer, AlertDescriptionText, CustomAlertTitle, SnackbarContainer } from './styles';

const CustomAlert = () => {
  const { alert, clear, alertText, alertTitle } = useAlertContext();
  const isSuccess = alert === 'SUCCESS';

  if (alert === 'NONE' || !alert) return null;

  return (
    <SnackbarContainer key={alert} open={!!alert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <AlertContainer
        variant="outlined"
        severity={isSuccess ? 'success' : 'error'}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              clear();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <CustomAlertTitle>{alertTitle}</CustomAlertTitle>
        <AlertDescriptionText id={alert}>{alertText}</AlertDescriptionText>
      </AlertContainer>
    </SnackbarContainer>
  );
};

export default CustomAlert;
