import AlertContext from 'contexts/AlertContext/AlertContext';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import getAccessProfiles from 'services/accessProfiles';
import getApprovalGroup from 'services/approvalGroups';
import getDepartments from 'services/departments';
import getRoles from 'services/roles';
import { hasApiError } from 'services/utils/isApiError';
import { AccessProfile } from 'types/accessProfile';
import { ApprovalGroup } from 'types/approvalGroup';
import { Department } from 'types/department';
import { Role } from 'types/role';

type JobFunctionContextDataType = {
  approvalGroup: ApprovalGroup[];
  accessProfiles: AccessProfile[];
  departments: Department[];
  roles: Role[];
  companyId?: number;
  errorJobFunctionASyncServices: boolean;
};

const JobFunctionContext = createContext({} as JobFunctionContextDataType);

const JobFuntionProvider = ({ children }: PropsWithChildren) => {
  const alert = useContext(AlertContext);
  const { companyId, userId } = useParams();

  const [approvalGroup, setApprovalGroup] = useState<ApprovalGroup[]>([]);
  const [accessProfiles, setAccessProfiles] = useState<AccessProfile[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [errorJobFunctionASyncServices, setErrorJobFunctionASyncServices] = useState<boolean>(false);

  const getJobFuntions = async (_companyId: number, _userId: number) => {
    const responseApprovalGroup = await getApprovalGroup(_companyId);
    const responseDepartments = await getDepartments(_companyId, _userId);
    const responseRoles = await getRoles(_companyId, _userId);
    const responseAccessProfiles = await getAccessProfiles(_companyId);

    if (hasApiError([responseApprovalGroup, responseAccessProfiles, responseDepartments, responseRoles])) {
      setErrorJobFunctionASyncServices(true);
      return;
    }

    setApprovalGroup(responseApprovalGroup as ApprovalGroup[]);
    setAccessProfiles(responseAccessProfiles as AccessProfile[]);
    setDepartments(responseDepartments as Department[]);
    setRoles(responseRoles as Role[]);
  };

  useEffect(() => {
    const validCompanyIdNumber = Number(companyId);
    const validUserId = Number(userId);

    if (!isNaN(validCompanyIdNumber) && !isNaN(validUserId)) {
      getJobFuntions(validCompanyIdNumber, validUserId);
    } else {
      setErrorJobFunctionASyncServices(true);
    }
  }, []);

  useEffect(() => {
    if (errorJobFunctionASyncServices) {
      alert.defaultAlerts.defaultError();
    }
  }, [errorJobFunctionASyncServices, alert]);

  return (
    <JobFunctionContext.Provider
      value={{
        approvalGroup,
        accessProfiles,
        departments,
        roles,
        companyId: Number(companyId),
        errorJobFunctionASyncServices,
      }}
    >
      {children}
    </JobFunctionContext.Provider>
  );
};

function useJobFunctionContext() {
  const context = useContext(JobFunctionContext);
  if (context === undefined) {
    throw new Error('JobFunctionContext can`t be initialized');
  }
  return context;
}

export { JobFuntionProvider, useJobFunctionContext, JobFunctionContextDataType };
export default JobFunctionContext;
