import useTranslate from 'hooks/useTranslate';
import { useState, createContext, ReactNode, useContext, useCallback, useMemo } from 'react';
import messages from './messages';
import { AlertType } from './types';

type DefaultAlertsType = {
  inputFieldRequired: (timeout?: number) => void;
  defaultError: (timeout?: number) => void;
};

type TAlertContext = {
  alert: AlertType;
  alertText: string;
  alertTitle: string;
  success: (title: string, text: string, timeout?: number) => void;
  error: (title: string, text: string, timeout?: number) => void;
  defaultAlerts: DefaultAlertsType;
  clear: () => void;
};

const AlertContext = createContext<TAlertContext>({} as TAlertContext);
AlertContext.displayName = 'AlertContext';

type AlertProviderProps = {
  children: ReactNode;
};

const AlertProvider = ({ children }: AlertProviderProps) => {
  const { translate } = useTranslate();
  const [alert, setAlert] = useState<AlertType>('NONE');
  const [alertText, setAlertText] = useState('');
  const [alertTitle, setAlertTitle] = useState('');

  const success = useCallback((title: string, text: string, timeout = 0) => {
    setAlertTitle(title);
    setAlertText(text);
    setAlert('SUCCESS');
    setTimeout(() => {
      setAlert('NONE');
    }, timeout * 1000 || 8000);
  }, []);

  const error = useCallback((title: string, text, timeout = 0) => {
    setAlertTitle(title);
    setAlertText(text);
    setAlert('ERROR');
    setTimeout(() => {
      setAlert('NONE');
    }, timeout * 1000 || 8000);
  }, []);

  const clear = useCallback(() => {
    setAlert('NONE');
  }, []);

  const defaultAlerts = useMemo<DefaultAlertsType>(
    () => ({
      inputFieldRequired: (timeout) => {
        error(
          translate(messages.defaultInputFieldRequiredTitle),
          translate(messages.defaultInputFieldRequiredDescription),
          timeout,
        );
      },
      defaultError: (timeout) => {
        error(translate(messages.defaultErrorTitle), translate(messages.defaultErrorDescription), timeout);
      },
    }),
    [error, translate],
  );

  const value = useMemo(
    () => ({
      alert,
      alertText,
      alertTitle,
      success,
      error,
      clear,
      defaultAlerts,
    }),
    [alert, alertText, alertTitle, clear, defaultAlerts, error, success],
  );

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};

function useAlertContext() {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlertContext must be used within a AlertProvider');
  }
  return context;
}

export { AlertProvider, useAlertContext, TAlertContext };
export default AlertContext;
